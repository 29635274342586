import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {CampaignPage} from './CampaignPage'

const CompaignWrapper: FC = () => {
  return (
    <>
      <CampaignPage />
    </>
  )
}

export default CompaignWrapper
