import clsx from 'clsx'
import React, {useEffect, useRef} from 'react'
import {Button} from 'react-bootstrap'
import {useParams} from 'react-router'
import {ToastContainer} from 'react-toastify'
import {getUploadUrl, uploadFile} from '../../../../app/modules/auth/core/_requests'
import {ToastMessage} from '../../../../app/shared/ToastMessage'
import {getBase64} from '../../../../app/utils/common'
import {KTSVG} from '../../../helpers'
import './FileUpload.scss'

export default function FileUpload({
  setFileData,
  fileData,
  showError,
  setBinaryData,
  setFieldValue,
  fieldValue,
  reference,
}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const {id} = useParams()

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays: any = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, {type: contentType})
    return blob
  }

  const handleFileChange = async (event: any) => {
    const fileObj = event.target.files && event.target.files[0]
    let file = event.target.files[0]
    let fileExtension = event.target.files[0].name.split('.').pop()

    const contentType = `image/${fileExtension}`
    let base64Data

    var reader = new FileReader()

    getBase64(file)
      .then((result) => {
        file['base64'] = result
        base64Data = file['base64'].split(',')[1]
        const blob = b64toBlob(base64Data, contentType)
        setBinaryData(blob)
      })
      .catch((err) => {
        ToastMessage('Something went wrong!', 'error')
      })
    setFileData({
      details: event.target.files[0],
      path: URL.createObjectURL(event.target.files[0]),
    })

    const formData = new FormData()
    formData.append('file', event.target.files[0])

    if (!fileObj) {
      return
    }

    event.target.value = null
  }

  const removeFile = () => {
    setFileData({details: null, path: ''})
    setFieldValue('')
  }

  return (
    <div
      className={clsx(
        'border border-gray-600 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3',
        {'error-border': showError}
      )}
      // ref={reference ? reference : null}
    >
      <div className='upload-files-container text-center'>
        <div className='text-center my-2'>
          <KTSVG path='/media/icons/upload.svg' />
        </div>
        <input
          type='file'
          accept='image/*'
          ref={inputRef}
          onChange={handleFileChange}
          style={{display: 'none'}}
        />

        <ToastContainer />
        <Button
          onClick={handleClick}
          variant='outline-dark'
          className='rounded-pill bg-secondary file-upload-btn'
          size='sm'
        >
          Select
        </Button>
      </div>

      {fileData?.details && fileData.path && (
        <div className='card border py-2 px-0 mt-2'>
          <div className='d-flex justify-content-around align-items-center'>
            <div className='file-thumbnail' style={{backgroundImage: `url(${fileData.path})`}} />
            {/* <img className='h-20px w-20px mr-1 ' src={fileData.path} alt='logo' /> */}
            <div className='file-name'>
              <small>{fileData?.details?.name}</small>
            </div>
            <div>
              <i
                className='bi bi-x-lg text-dark fw-600 cursor-pointer cancel-icon'
                onClick={() => {
                  removeFile()
                }}
              ></i>
            </div>
          </div>
        </div>
      )}

      {fieldValue && id && !fileData?.details && !fileData?.path && (
        <div className='card border py-2 px-0 mt-2'>
          <div className='d-flex justify-content-around align-items-center'>
            <div className='file-thumbnail' style={{backgroundImage: `url(${fieldValue})`}} />
            {/* <img className='h-20px w-20px mr-1 ' src={fileData.path} alt='logo' /> */}
            <div className='file-name'>
              <small>{fieldValue}</small>
            </div>
            <div>
              <i
                className='bi bi-x-lg text-dark fw-600 cursor-pointer cancel-icon'
                onClick={() => {
                  removeFile()
                }}
              ></i>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
