export const TRIGGER_LOGIN = 'TRIGGER_LOGIN'
export const TRIGGER_LOGIN_SUCCESS = 'TRIGGER_LOGIN_SUCCESS'
export const TRIGGER_LOGIN_FAILED = 'TRIGGER_LOGIN_FAILED'

export const TRIGGER_LOGOUT = 'TRIGGER_LOGOUT'
export const TRIGGER_LOGOUT_SUCCESS = 'TRIGGER_LOGOUT_SUCCESS'
export const TRIGGER_LOGOUT_FAILED = 'TRIGGER_LOGOUT_FAILED'

export const TRIGGER_REGISTER = 'TRIGGER_REGISTER'
export const TRIGGER_REGISTER_SUCCESS = 'TRIGGER_REGISTER_SUCCESS'
export const TRIGGER_REGISTER_FAILED = 'TRIGGER_REGISTER_FAILED'

export const TRIGGER_SET_PASSWORD = 'TRIGGER_SET_PASSWORD'
export const TRIGGER_SET_PASSWORD_SUCCESS = 'TRIGGER_SET_PASSWORD_SUCCESS'
export const TRIGGER_SET_PASSWORD_FAILED = 'TRIGGER_SET_PASSWORD_FAILED'

export const TRIGGER_CHANGE_PASSWORD = 'TRIGGER_CHANGE_PASSWORD'
export const TRIGGER_CHANGE_PASSWORD_SUCCESS = 'TRIGGER_CHANGE_PASSWORD_SUCCESS'
export const TRIGGER_CHANGE_PASSWORD_FAILED = 'TRIGGER_CHANGE_PASSWORD_FAILED'

export const TRIGGER_FORGET_PASSWORD = 'TRIGGER_FORGET_PASSWORD'
export const TRIGGER_FORGET_PASSWORD_SUCCESS = 'TRIGGER_FORGET_PASSWORD_SUCCESS'
export const TRIGGER_FORGET_PASSWORD_FAILED = 'TRIGGER_FORGET_PASSWORD_FAILED'

export const TRIGGER_SEARCH_KEYWORD = 'TRIGGER_SEARCH_KEYWORD'

export const TRIGGER_CAMPAIGN_DETAILS = 'TRIGGER_CAMPAIGN_DETAILS'
export const TRIGGER_USER_DETAILS = 'TRIGGER_USER_DETAILS'
export const TRIGGER_QUESTION_DETAILS = 'TRIGGER_QUESTION_DETAILS'

export const TRIGGER_PRIZE_DETAILS = 'TRIGGER_PRIZE_DETAILS'

export const TRIGGER_ANSWER_DETAILS = 'TRIGGER_ANSWER_DETAILS'
export const TRIGGER_START_TIME = 'TRIGGER_START_TIME'
export const TRIGGER_END_TIME = 'TRIGGER_END_TIME'

export const TRIGGER_OTP = 'TRIGGER_OTP'
export const TRIGGER_MOBILE = 'TRIGGER_MOBILE'

export const TRIGGER_PRIZE_INDEX = 'TRIGGER_PRIZE_INDEX'
export const TRIGGER_IS_ANSWER_CORRECT = 'TRIGGER_IS_ANSWER_CORRECT'
export const TRIGGER_SELECTED_BOX_INDEX = 'TRIGGER_SELECTED_BOX_INDEX'

export const TRIGGER_QUESTIONNAIRE_CURRENT_PAGE = 'TRIGGER_QUESTIONNAIRE_CURRENT_PAGE'
export const TRIGGER_CAMPAIGN_TABLE_CURRENT_PAGE = 'TRIGGER_CAMPAIGN_TABLE_CURRENT_PAGE'
export const TRIGGER_CAMPAIGN_DETAILS_CURRENT_PAGE = 'TRIGGER_CAMPAIGN_DETAILS_CURRENT_PAGE'
