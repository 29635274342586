import React from 'react'
import {QuestionnaireTable} from './QuestionnaireTable/QuestionnaireTable'

export default function QuestionnaireWrapper() {
  return (
    <div>
      <QuestionnaireTable />
    </div>
  )
}
